import { combineReducers } from "redux";
import auth from "./auth";
import home from "./home";
import claim from "./claim";
import driver from "./driver";
import cashback_rewards from "./cashback_rewards";

const root = combineReducers({
    auth,
    home,
    claim,
    driver,
    cashback_rewards,
});

export default root;