
//auth
export const LOGIN_RESPONSE= "LOGIN_RESPONSE"
export const  LOGOUT_RESPONSE ="LOGOUT_RESPONSE"
export const FORGOT_PASSWORD_RESPONSE= "FORGOT_PASSWORD_RESPONSE"
export const VERIFY_OTP_RESPONSE= "VERIFY_OTP_RESPONSE"
export const RESET_PASSWORD_RESPONSE= "RESET_PASSWORD_RESPONSE"

//home
export const HOME_DATA_RESPONSE ="HOME_DATA_RESPONSE"
export const ADMIN_LIST_RESPONSE = "ADMIN_LIST_RESPONSE"
export const DRIVER_LIST_RESPONSE ="DRIVER_LIST_RESPONSE"
export const DRIVER_DETAILS_RESPONSE ="DRIVER_DETAILS_RESPONSE"
export const DRIVER_SEARCH_RESPONSE ="DRIVER_SEARCH_RESPONSE"
// export const ADMIN_PHOTOS_RESPONSE ="ADMIN_PHOTOS_RESPONSE"
// export const ADMIN_GET_PHOTOS_RESPONSE ="ADMIN_GET_PHOTOS_RESPONSE"
// export const TERMS_CONDITION_RESPONSE ="TERMS_CONDITION_RESPONSE"
// export const PRIVACY_POLICY_RESPONSE ="PRIVACY_POLICY_RESPONSE"
// export const DELETE_PIC_ADMIN_RESPONSE ="DELETE_PIC_ADMIN_RESPONSE"
//

//claims
export const CLAIM_LIST_RESPONSE ="CLAIM_LIST_RESPONSE"
export const ACCIDENTAL_CLAIM_LIST_RESPONSE ="ACCIDENTAL_CLAIM_LIST_RESPONSE"
export const CLAIM__DETAILS_LIST_RESPONSE ="CLAIM__DETAILS_LIST_RESPONSE"