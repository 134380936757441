import {
    CLAIM_LIST_RESPONSE,
    CLAIM__DETAILS_LIST_RESPONSE,
    ACCIDENTAL_CLAIM_LIST_RESPONSE
  
   
} from "../action/type";

const Initial_state = {
    claim_list_data: "",
    accidental_list_data:"",
    claim_details_data:"",

}

export default (state = Initial_state, action) => {
    const { type, payload } = action;
    switch (type) {
        case CLAIM_LIST_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    claim_list_data: payload?.data
                } 
            };
        case ACCIDENTAL_CLAIM_LIST_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    accidental_list_data: payload?.data
                } 
            };
        case CLAIM__DETAILS_LIST_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    claim_details_data: payload?.data
                } 
            };
      
         
        default:
            return state;
    } 
}